import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import * as Icon from 'react-feather'

export interface Props {
  image?: string
  languageCode: string
  pax?: string
  size?: string
  title: string
  URL?: string
}

export const Room = memo(function Room({
  image,
  languageCode,
  pax,
  size,
  title,
  URL,
}: Props) {
  return (
    <Container>
      <Link to={URL ? URL : ''}>
        {image ? (
          <Image
            className="swiper-lazy"
            style={{ backgroundImage: `url(${image})` }}
          />
        ) : null}
        <TextWrap className="room-text-wrap" dial={5}>
          <Info>
            <Title>{title}</Title>
            <InfoWrap className="room-info-wrap" dial={2} row>
              {size ? (
                <Size dial={5} row>
                  <Icon.Box />
                  {size}
                </Size>
              ) : null}
              {pax ? (
                <Pax dial={5} row>
                  <Icon.User />
                  {pax}
                </Pax>
              ) : null}
            </InfoWrap>
          </Info>
          {URL ? (
            <CTA
              className="room-cta"
              label={useVocabularyData('details', languageCode)}
              noActions
            />
          ) : null}
        </TextWrap>
      </Link>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  a {
    display: flex;
    width: 100%;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    &:hover {
      .swiper-lazy {
        transform: scale(1);
      }
      .room-text-wrap {
        h2 {
          &:before {
            height: 2.25rem;
          }
        }
        &:before {
          opacity: 1;
          transform: scale(1);
        }
      }
      .room-info-wrap {
        opacity: 1;
        transform: translateY(0);
      }
      .room-cta {
        bottom: 0;
        opacity: 1;
      }
    }
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: 0.3s ease-out;
`

const TextWrap = styled(FlexBox)`
  width: calc(100% - 5rem);
  height: calc(100% - 5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 6rem 1.5rem;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 2;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.03);
    transition: 0.3s ease-out;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    top: 0;
    left: 0;
    &:before {
      display: none;
    }
  }
`

const Info = styled.div`
  width: 100%;
  position: relative;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5rem;
  position: relative;
  &:before {
    content: '';
    width: 2px;
    height: 0;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    position: absolute;
    top: calc(-100% - 0.5rem);
    left: 50%;
    transition: 0.3s ease-out;
  }

  @media (max-width: 1023px) {
    &:before {
      display: none;
    }
  }
`

const InfoWrap = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.625rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 0.75rem;
  opacity: 0;
  position: absolute;
  bottom: -1.875rem;
  right: 0;
  left: 0;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  transform: translateY(0.5rem);
  svg {
    height: 1rem;
    margin-right: 0.5625rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Size = styled(FlexBox)`
  margin-right: 1.25rem;
`

const Pax = styled(FlexBox)``

const CTA = styled(Button)`
  position: absolute;
  bottom: -1rem;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  transition: 0.3s ease-out;

  @media (max-width: 1023px) {
    opacity: 1;
    bottom: 0;
  }
`

import 'swiper/swiper-bundle.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import React, { memo } from 'react'
import SwiperCore, { Autoplay, Lazy, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Props as RoomProps, Room } from './Room'

SwiperCore.use([Autoplay, Lazy, Pagination])

export interface Props {
  description?: string
  label?: string
  rooms?: RoomProps[]
  title?: string
  variant?: Variant
}

export const RoomsSlider = memo(function RoomsSlider({
  description,
  label,
  rooms,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container>
      {label ? (
        <FadeInOutAnimation>
          <Label>{label}</Label>
        </FadeInOutAnimation>
      ) : null}
      {title ? (
        <FadeInOutAnimation>
          <Title>{title}</Title>
        </FadeInOutAnimation>
      ) : null}
      {description ? (
        <FadeInOutAnimation>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInOutAnimation>
      ) : null}
      <FadeInOutAnimation>
        <Slider variant={variant}>
          {rooms ? (
            <>
              <Swiper
                autoplay={{ delay: 4000 }}
                breakpoints={{
                  0: {
                    slidesPerView: 1.05,
                  },
                  575: {
                    slidesPerView: 3,
                  },
                }}
                grabCursor
                lazy
                loop={rooms.length > 1 ? true : false}
                pagination={
                  rooms.length > 1
                    ? {
                        clickable: true,
                      }
                    : false
                }
                preloadImages={false}
                spaceBetween={2}
              >
                {rooms.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Room {...item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : null}
        </Slider>
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem 0;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 767px) {
    margin: 5.25rem 0 6.25rem;
  }
`

const Label = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 0.9375rem;
  opacity: 0.4;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
  }
  &:before {
    margin-right: 1.5rem;
  }
  &:after {
    margin-left: 1.5rem;
  }
`

const Description = styled.div`
  max-width: 46.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin: auto;
  padding: 0 1.5rem;
`

const Slider = styled.div<SliderProps>`
  margin-top: 3.75rem;

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          max-width: 73.5625rem;
          margin-right: auto;
          margin-left: auto;
          padding: 0 1.5rem;

          .offer-title {
            font-size: 1.5rem;
            line-height: 2rem;
          }

          @media (max-width: 767px) {
            padding: 0;
          }
        `
    }
  }}

  .swiper-container {
    display: flex;
    flex-wrap: wrap;
  }

  .swiper-pagination {
    width: calc(100% - 1.5rem);
    display: flex;
    justify-content: center;
    order: 2;
    margin-top: 1.875rem;
    position: relative;
    bottom: 0;
    span {
      width: 3rem;
      height: 0.1875rem;
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      border-radius: 0;
      margin: 0 0.3125rem;
      opacity: 0.2;
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }

  @media (max-width: 767px) {
    .swiper-container {
      padding-left: 1.5rem;
    }
  }
`

interface SliderProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
